<template>
  <main class="main">
    <!-- Generating form fo each guest based on the number of guests-->
    <ResForm
      v-for="(item, index) in totalGuests"
      :key="index"
      :num="item"
      :id="index"
      :totalGuests="totalGuests"
      :allGuestInfo="allGuestInfo"
      :selectedHotel="selectedHotel"
      ref="formArray"
      @formCompleted="nextForm"
      @goPayment="payDialog"
    />
    <div>
      <el-dialog title="Payment" :visible.sync="dialogFormVisible" center width="30%">
  <el-form :model="form">
    <h4 style="text-align:center; font-weight:bold;">AED {{Total_Payment_price}}</h4>
    <br>
    <el-form-item label="Payment Type:" :label-width="formLabelWidth">
      <el-select v-model="form.type" placeholder="Select Payment Type">
        <el-option label="Agent Balance" value="Balance"></el-option>
        <el-option label="Credit Card" value="Credit Card"></el-option>
      </el-select>
    </el-form-item>
    <p style="text-align:center; ">Agent Balance: AED {{agtbalance}}</p>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">Cancel</el-button>
    <el-button type="primary" @click="pay">Confirm</el-button>
  </span>
</el-dialog>
    </div>
  </main>
</template>

<script>
import ResForm from "../components/ResForm.vue";
import axios from "axios";

export default {
  name: "Reservation",
  data() {
    return {
      allGuestInfo: [],
      count: 0,
      bookingid: 0,
      Total_Payment_price: 0,
      agtbalance: 0,
		agent : {},
      agtid: 0,
      checkin: '',
      checkout: '',
      noofrooms: 0,
      noofadults: 0,
      noofchildren: 0,
      hotelname: '',
      rating: 0,
      address: '',
      dialogFormVisible: false,
        form: {
          name: '',
          type: '',
        },
        formLabelWidth: '120px',
    };
  },
  components: {
    ResForm,
  },
  props: {
    guestData: Object,
    selectedHotel: Object,
  },
  methods: {
    async getagtBalance(agtid) {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/agtBalance/${agtid}`
            );
            this.agtbalance = response.data[0].agtbalance_ds;
        } catch (err) {
        console.log(err);
        }
    },
    async getBookingID() {
      try {
      const response = await axios.get(
        `https://www.desertlinktours.com/api/hotels/BookingID`
      );
      this.bookingid = response.data[0].bookingid;
      this.Total_Payment_price = response.data[0].totalprice;
      this.checkin = response.data[0].checkin;
      this.checkout = response.data[0].checkout;
      this.noofrooms = response.data[0].noofrooms;
      this.noofadults = response.data[0].noofadults;
      this.noofchildren = response.data[0].noofchildren;
      this.hotelname = response.data[0].hotelname;
      this.rating = response.data[0].rating;
      this.address = response.data[0].street+', '+response.data[0].area+', '+response.data[0].district+', '+response.data[0].city+', '+response.data[0].country;
      //console.log(this.bookingid);
      
      } catch (err) {
      console.log(err);
      }
    },
    async postGuest(){
      //console.log(this.bookingid);
      try {
        await axios.post("https://www.desertlinktours.com/api/hotels/Guest", {
          guest_detail: this.allGuestInfo, len: this.allGuestInfo.length, BookingID: this.bookingid
        });
        } catch (err) {
        console.log(err);
        }
    },
    async UpdateBalance(){
      //console.log(this.bookingid);
      try {
        await axios.post("https://www.desertlinktours.com/api/hotels/AgtBalance", {
          AB: this.agtbalance - this.Total_Payment_price, AID: this.agtid
        });
        } catch (err) {
        console.log(err);
        }
    },
    async postEmail(){
      //console.log(this.bookingid);
      try {
        await axios.post("https://www.desertlinktours.com/api/hotels/Email", {
          BookingID: this.bookingid ,guest_name: this.allGuestInfo[0].fname +" "+this.allGuestInfo[0].lname ,guest_eamil: this.allGuestInfo[0].email, guest_country: this.allGuestInfo[0].country, 
          totalprice: this.Total_Payment_price, checkin:this.checkin ,checkout: this.checkout, rooms: this.noofrooms, adults: this.noofadults,
          child: this.noofchildren, hotelname: this.hotelname, rating: this.rating, address: this.address
        });
        } catch (err) {
        console.log(err);
        }
    },
    // If form is completed and there are no validation errors, saves guest data and focusesto the next form
    nextForm(personalInfo) {
      if (
        this.allGuestInfo.find((item) => item.formId === personalInfo.formId)
      ) {
        let sameFormIndex = this.allGuestInfo.findIndex(
          (item) => item.formId === personalInfo.formId
        );
        this.allGuestInfo.splice(sameFormIndex, 1);
      }
      this.allGuestInfo.push(personalInfo);
      if (personalInfo.formId + 1 !== this.totalGuests) {
        this.count = personalInfo.formId + 1;
        this.$refs.formArray[this.count].focus();
      }
    },
    // Sends guest info to parent component (App) to make it available in /payment route
    payDialog() {
      this.getBookingID();
      this.getagtBalance(this.agtid);
      this.dialogFormVisible  = true;
      //console.log(this.address);
      //this.$emit("sendGuestInfo", this.allGuestInfo);
      //this.getBookingID();
      //console.log(this.allGuestInfo);
      //console.log(this.allGuestInfo.length);
      
    },
    pay(){
      if(this.form.type =='Balance' || this.form.type =='Credit Card')
      {
        if(this.form.type =='Balance' && this.agtbalance >= this.Total_Payment_price){
          this.dialogFormVisible  = false;
          //this.getBookingID();
          this.postGuest();
          this.postEmail();
          this.UpdateBalance();
          this.$message({
              type: 'success',
              message: 'Agent Balance is ' + (this.agtbalance - this.Total_Payment_price)
            });
          this.$emit("sendGuestInfo", this.allGuestInfo);
          this.$router.push({
            name: "Payment",
            params: { selectedHotel: this.selectedHotel },
            });
        }
          else{
          this.$message({
              type: 'warning',
              message: 'Agent Balance in insufficient'
            });
        }
      }
      else{
        this.$message({
            type: 'warning',
            message: 'Select Payment Mode'
          });
      }
    },
  },
  computed: {
    //Calculate total number of guests (adults + children)
    totalGuests() {
      //return this.guestData.adult + this.guestData.children;
      return this.guestData.room;
    },
  },
  mounted() {
    //Focus on the first form when the component is mounted
    this.$refs.formArray[0].focus();
	 this.agent = JSON.parse(localStorage.getItem('agent'))
	 this.agtid = this.agent["Agtid"]
  },
};
</script>

<style scoped>
.main {
  background-color: #f5f5f5;
  padding: 100px 50px 20px 50px;
  min-height: 100vh;
}
.btn-block {
  margin-top: 10px;
  text-align: center;
}
button {
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #1a4a8d;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
button:hover {
  background: #14396d;
}
</style>
