<template>
  <div class="testbox d-flex flex-column">
    <form>
      <fieldset>
        <legend class="rounded">Guest For Room - {{ num }}</legend>
        <div class="columns">
          <!-- Name input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.fname.$error }"
            ></div>
            <label class="form__label" for="fname"
              >First Name<span>*</span></label
            >
            <input
              class="form__input"
              v-model.trim="$v.fname.$model"
              id="fname"
              type="text"
              name="fname"
              placeholder="Ahmed"
              ref="input"
            />
            <div class="error" v-if="!$v.fname.required && $v.fname.$dirty">
              Name is required
            </div>
            <div class="error" v-if="!$v.fname.minLength">
              Name must have at least
              {{ $v.fname.$params.minLength.min }} letters.
            </div>
          </div>
          <!-- Last name input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.lname.$error }"
            ></div>
            <label class="form__label" for="lname">
              Last Name<span>*</span></label
            >
            <input
              class="form__input"
              v-model.trim="$v.lname.$model"
              id="lname"
              type="text"
              name="lname"
              placeholder="Ali"
            />
            <div class="error" v-if="!$v.lname.required && $v.lname.$dirty">
              Last name is required
            </div>
            <div class="error" v-if="!$v.lname.minLength">
              Name must have at least
              {{ $v.lname.$params.minLength.min }} letters.
            </div>
          </div>
          <!-- Age input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.age.$error }"
            >
              <label class="form__label" for="age">Age<span>*</span></label><br>
              <input
                class="form__input"
                id="age"
                type="number"
                name="age"
                v-model.trim="$v.age.$model"
                placeholder="Must be older than 7"
                min="8"
                max="150"
              />
            </div>
            <div class="error" v-if="!$v.age.required && $v.age.$dirty">
              Age is required
            </div>
            <div class="error" v-if="!$v.age.minValue">
              Must be older than {{ $v.age.$params.minValue.min }}
            </div>
          </div>
          <!-- Sex input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.sex.$error }"
            ></div>
            <label class="d-block mb-1" for="sex">Sex<span>*</span></label>
            <select v-model.trim="$v.sex.$model" name="sex" id="sex">
              <option value="" disabled selected>Select Your Sex</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <div class="error" v-if="!$v.sex.required && $v.sex.$dirty">
              Please enter your sex
            </div>
          </div>
          
          <!-- T.C. Identity No. input area and validations 
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.tc.$error }"
            ></div>
            <label for="tc">Identity No.<span>*</span></label>
            <input
              v-model.trim="$v.tc.$model"
              id="tc"
              type="text"
              name="tc"
              placeholder="Identity No."
            />

            <div class="error" v-if="!$v.tc.required && $v.tc.$dirty">
              Please enter your identity number
            </div>
            <div
              class="error"
              v-if="$v.tc.$model !== '' && !$v.tc.identityCheck && $v.tc.$dirty"
            >
              Please enter a valid identity number
            </div>
          </div> -->
          <!-- HES Code input area and validations 
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.hes.$error }"
            ></div>
            <label for="hes">HES Code<span>*</span></label>
            <input
              v-model.trim="$v.hes.$model"
              id="hes"
              type="text"
              name="hes"
              placeholder="C5V9-4567-24"
            />
            <div class="error" v-if="!$v.hes.required && $v.hes.$dirty">
              Please enter your HES Code
            </div>
            <div
              class="error"
              v-if="$v.hes.$model !== '' && !$v.hes.hesCheck && $v.hes.$dirty"
            >
              Please enter a valid HES Code
            </div>
          </div>-->
          <!-- E-mail input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.email.$error }"
            ></div>
            <label class="form__label" for="email"
              >Email Address<span>*</span></label
            >
            <input
              class="form__input"
              v-model.trim="$v.email.$model"
              id="email"
              type="email"
              name="email"
              placeholder="fatihozoglu@yahoo.com"
            />
            <div class="error" v-if="!$v.email.required && $v.email.$dirty">
              Email is required
            </div>
            <div class="error" v-if="!$v.email.email">
              Please enter a valid e-mail adress
            </div>
          </div>
          <!-- Phone input area and validations -->
          <div class="item">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.phone.$error }"
            ></div>
            <label for="phone">Phone<span>*</span></label>
            <br>
            <vue-country-code
                @onSelect="onSelect"
                :defaultCountry="ipcountry"
                :enabledCountryCode="true"
                :preferredCountries="['AE', 'PK', 'IN']">
            </vue-country-code>
            <input
              v-model.trim="$v.phone.$model"
              id="phone"
              type="number"
              name="phone"
              placeholder="5395845151"
            />
            <div class="error" v-if="!$v.phone.required && $v.phone.$dirty">
              Phone number is required
            </div>
            <div class="error" v-if="!$v.phone.phoneCheck && $v.phone.$dirty">
              Please enter a valid phone number
            </div>
          </div>
          <!-- Country input area and validations -->
          <!--<div class="item">
            <label for="country">Country<span>*</span></label><br>
            <select id="country" name="country" v-model.trim="$v.country.$model">
              <option value="" disabled selected>Select Your Country</option>
              <option value="Afganistan">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antigua & Barbuda">Antigua & Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bonaire">Bonaire</option>
              <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
              <option value="Botswana">Botswana</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
              <option value="Brunei">Brunei</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Canary Islands">Canary Islands</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">Central African Republic</option>
              <option value="Chad">Chad</option>
              <option value="Channel Islands">Channel Islands</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos Island">Cocos Island</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote DIvoire">Cote DIvoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Curaco">Curacao</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="East Timor">East Timor</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands">Falkland Islands</option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Ter">French Southern Ter</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Great Britain">Great Britain</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guinea">Guinea</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="Indonesia">Indonesia</option>
              <option value="India">India</option>
              <option value="Iran">Iran</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea North">Korea North</option>
              <option value="Korea Sout">Korea South</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Laos">Laos</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macau">Macau</option>
              <option value="Macedonia">Macedonia</option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Malawi">Malawi</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Midway Islands">Midway Islands</option>
              <option value="Moldova">Moldova</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Nambia">Nambia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherland Antilles">Netherland Antilles</option>
              <option value="Netherlands">Netherlands (Holland, Europe)</option>
              <option value="Nevis">Nevis</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau Island">Palau Island</option>
              <option value="Palestine">Palestine</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Phillipines">Philippines</option>
              <option value="Pitcairn Island">Pitcairn Island</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Republic of Montenegro">Republic of Montenegro</option>
              <option value="Republic of Serbia">Republic of Serbia</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russia">Russia</option>
              <option value="Rwanda">Rwanda</option>
              <option value="St Barthelemy">St Barthelemy</option>
              <option value="St Eustatius">St Eustatius</option>
              <option value="St Helena">St Helena</option>
              <option value="St Kitts-Nevis">St Kitts-Nevis</option>
              <option value="St Lucia">St Lucia</option>
              <option value="St Maarten">St Maarten</option>
              <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
              <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
              <option value="Saipan">Saipan</option>
              <option value="Samoa">Samoa</option>
              <option value="Samoa American">Samoa American</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome & Principe">Sao Tome & Principe</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syria">Syria</option>
              <option value="Tahiti">Tahiti</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania">Tanzania</option>
              <option value="Thailand">Thailand</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad & Tobago">Trinidad & Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks & Caicos Is">Turks & Caicos Is</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Erimates">United Arab Emirates</option>
              <option value="United States of America">United States of America</option>
              <option value="Uraguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Vatican City State">Vatican City State</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Vietnam">Vietnam</option>
              <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
              <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
              <option value="Wake Island">Wake Island</option>
              <option value="Wallis & Futana Is">Wallis & Futana Is</option>
              <option value="Yemen">Yemen</option>
              <option value="Zaire">Zaire</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <div class="error" v-if="!$v.country.required">
              country is required
            </div>
            
          </div>-->
          <div class="item">
          <label for="country">Country<span>*</span></label><br>
          <vue-country-code
                @onSelect="onSelect1"
                :defaultCountry="ipcountry"
                :dropdownOptions="{ disabledDialCode: true }">
            </vue-country-code>
            <div class="error" v-if="!$v.country.required">
              country is required
            </div>
          </div>
        </div>
      </fieldset>
      <br />
      <!-- Showing "Next Guest" button for forms except the last form item-->
      <button v-if="id !== totalGuests - 1" @click.prevent="checkCompletion">
        Next Guest
      </button>
      <!-- Showing "Go to Payment" button for the last form item to route to /payment Payment view component -->
      <button v-else @click.prevent="goPayment">Go to Payment</button>
    </form>
  </div>
</template>

<script>
import { FormValidation } from "../mixins/FormValidation";
import Vue from "vue";
import VueCountryCode from "vue-country-code";
Vue.use(VueCountryCode);

export default {
  name: "ResForm",
  mixins: [FormValidation],
  data() {
    return {
      fname: "",
      lname: "",
      age: "",
      email: "",
      sex: "",
      //tc: "",
      //hes: "",
      phone: "",
      country: "",
      ipcountry: '',
      phonecode: "",
    };
  },
  props: {
    num: Number,
    form: String,
    id: Number,
    totalGuests: Number,
    allGuestInfo: Array,
    selectedHotel: Object,
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onSelect({name, iso2, dialCode}) {
      // console.log(name, iso2, dialCode);
      this.phonecode = "+" + dialCode;
     },
     onSelect1({name, iso2, dialCode}) {
      // console.log(name, iso2, dialCode);
      this.country = name;
     },
    //Checking the form if it is completed and there is no error, if it is complete focusing next form else giving validation error
    checkCompletion() {
      //this.phone = this.phonecode + this.phone;
      //console.log(this.phone);
      if (this.$v.$dirty && !this.$v.$invalid) {
        this.$emit("formCompleted", {
          formId: this.id,
          fname: this.fname,
          lname: this.lname,
          age: this.age,
          email: this.email,
          sex: this.sex,
          //tc: this.tc,
          //hes: this.hes,
          phone: this.phonecode + this.phone,
          country: this.country,
        });

      //  console.log(this.country);
      } else {
        this.$v.$touch();
}
    },
    //Checking if all forms are complete and if they are, routing to /payment route
    goPayment() {
      this.checkCompletion();
      if (this.allGuestInfo.length === this.totalGuests) {
        this.$emit("goPayment");
        //this.$router.push({
        //  name: "Payment",
        //  params: { selectedHotel: this.selectedHotel },
       // });
      }
    },
  },
};
</script>

<style scoped>
div,
form,
select,
textarea,
label {
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 14px;
  color: #666;
  line-height: 22px;
  font-weight: bold;
  
}
h1 {
  position: absolute;
  margin: 0;
  font-size: 50px;
  color: #fff;
  z-index: 2;
  line-height: 83px;
}
legend {
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #1a4a8d;
}
textarea {
  width: calc(100% - 12px);
  padding: 5px;
}
.testbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 20px;
}
form {
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 8px #1a4a8d;
}
input,
select,
textarea {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
input {
  width: calc(100% - 50px);
  padding: 5px;
}
input[type="number"] {
  width: 200px;
  padding: 5px;
}
textarea {
  width: calc(100% - 12px);
  padding: 5px;
}
select {
  height: 30px;
}
.item:hover p,
.item:hover i,
.item input:hover,
.item select:hover,
.item textarea:hover {
  border: 1px solid transparent;
  box-shadow: 0 0 3px 0 #1a4a8d;
  color: #1a4a8d;
}
.item {
  position: relative;
  margin: 5px 0;
}
.item span {
  color: red;
}
.item i {
  right: 1%;
  top: 30px;
  z-index: 1;
}
.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.columns div {
  width: 33%;
}
.btn-block {
  margin-top: 10px;
  text-align: center;
}
button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #1a4a8d;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
button:hover {
  background: #14396d;
}
.error {
  color: red;
}
@media (min-width: 568px) {
  .name-item,
  .city-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .name-item input,
  .name-item div {
    width: calc(50% - 20px);
  }
  .name-item div input {
    width: 97%;
  }
  .name-item div label {
    display: block;
    padding-bottom: 5px;
  }
}

</style>
